<template>
  <div class="container">
    <PublicHeader />
    <!-- <PublicHeader :totalName="content.TypeName" /> -->
    <div class="title">{{ content.Ttile }}</div>
    <div class="time" v-if="content.Type == 'Notice'">{{content.ShowCreateTime | getLocalTime }}</div>
    <div class="content" v-html="content.Content"></div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userApi from '@/api/user'
import { getLocalTime,getLang } from '@/utils/validate'
export default {
  components: {
    PublicHeader
  },
  filters: {
    getLocalTime
  },
  data() {
    return {
      content: {}
    }
  },
  async mounted() {
    const contentId = this.$route.query.contentId
    const cMsg = await userApi.getContentDetail({
      lang: getLang(),
      contentId: contentId
    })
    this.content = cMsg.Data
  }
}
</script>

<style scoped>
.container {
  padding-top: 44px;
  min-height: 100vh;
  width: 100%;
  background: #F7F9FE;
  color: #515151;
}

.title {
  font-size: 16px;
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
}

.time {
  font-size: 12px;
  margin-bottom: 20px;
  text-align: center;
}

.content {
  margin: 10px;
  background: #ffffff;
  overflow: hidden;
  padding: 10px;
  border-radius: 4px;
}
</style>
